import React from 'react'

import TargetsList from 'components/common/Targets/TargetsList'

import css from './List.module.scss'

const List = ({ targets }) => {
  const byGoals = targets
    .sort((a, b) => a.goal.theOrder - b.goal.theOrder)
    .reduce((acc, item) => {
      if (!acc[item.goal.id]) {
        acc[item.goal.id] = {
          goal: item.goal,
          items: [],
        }
      }
      acc[item.goal.id].items.push(item)
      return acc
    }, {})

  return <div className={css.wrapper}>
    {Object.values(byGoals).map(({ goal, items }) => (
      <div className={css.container} key={goal.id}>
        <div className={css.title}>{goal.name}</div>
        <div className={css.items}>
          <TargetsList targets={items} />
        </div>
      </div>
    ))}
  </div>
}

export default List
