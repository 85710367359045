import React from 'react'

import css from './Hero.module.scss'

const Hero = ({ goals, title }) => {
  return <div className={css.wrapper}>
    <h1>{title}</h1>

    <div className={css.container}>
      <svg width="477" height="567" viewBox="0 0 477 567" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M405.545 22.1149C452.17 51.9281 475.776 108.14 476.16 175.031C476.544 241.916 453.703 319.408 407.519 391.634C361.336 463.86 300.578 517.107 239.704 544.822C178.825 572.539 117.896 574.697 71.2711 544.883C24.6464 515.07 1.04049 458.858 0.656338 391.967C0.272225 325.082 23.1131 247.591 69.2969 175.364C115.481 103.138 176.238 49.8911 237.112 22.1764C297.991 -5.54067 358.92 -7.69829 405.545 22.1149Z" stroke="white"/>
      </svg>
      {goals.results.map(item => <div className={css.item} key={item.id}><span>{item.name}</span></div>)}
    </div>
  </div>
}

export default Hero
