import React from "react"
import { useTranslation } from "react-i18next"

import SEO from "components/seo"

import Subscription from "../PageArticles/Subscription/Subscription"
import Hero from "./Hero"
import List from "./List"

const PageTargets = ({ targets, goals }) => {
  const { t } = useTranslation()

  const { metaTitle, metaDescription, metaKeywords, metaImage } = targets

  const title = metaTitle || t("targets_title")
  return (
    <>
      <SEO
        title={title}
        image={metaImage}
        description={metaDescription}
        keywords={metaKeywords}
      />
      <Hero goals={goals} title={title} />
      <List targets={targets.results} />
      <Subscription grey padding />
    </>
  )
}

export default PageTargets
