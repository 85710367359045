import React from "react"

import Layout from "components/Layout/Layout"

import useGoals from "hooks/useGoals"

import PageTargets from "components/PageTargets"
import useChangeLanguage from "hooks/useChangeLanguage"

const Targets = ({ pageContext: { lang, targets } }) => {
  useChangeLanguage(lang)

  return (
    <Layout color="white">
      <PageTargets targets={targets} goals={useGoals()} />
    </Layout>
  )
}

export default Targets
